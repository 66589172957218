//-----------------------------------------
// Color Settings
//-----------------------------------------
$color-black: #241F21;
$color-off-white: #FDFBEF;
$color-blue: #2D4658;
$color-blue-accent: #0F6DAC;
$color-peach: #FFA67F;
$color-peach-accent: #FD7658;
$color-dark-red: #A02D2D;
$color-red-accent: #D43636;
$color-green: #B9BAA4;
$color-green-accent: #AEC8BD;
