//-----------------------------------------
// Global
//-----------------------------------------
*,
*::before,
*::after {
  transition-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  transition-duration: 400ms;
}

//-----------------------------------------
// Base HTML Styles
//-----------------------------------------
html {
    min-height: 100%;
    font-size: 10px; // Easier REM values
}

body {
  background-color: $color-black;
}

//-----------------------------------------
// Containers
//-----------------------------------------
.container-fluid {
  padding: 0 5.5rem;
}

//-----------------------------------------
// Images
//-----------------------------------------
img {
  max-width: 100%;
  font-style: italic; //Offset `alt` text from surrounding copy
  vertical-align: middle; //Setting `vertical-align` removes the whitespace that appears under `img`
}

//-----------------------------------------
// Lists
//-----------------------------------------
li {
  > ul,
  > ol {
    margin-bottom: 0; // Remove extra vertical spacing when nesting lists.
  }
}

//-----------------------------------------
// Nav States
//-----------------------------------------
[data-nav-state='open'] {
  overflow: hidden;
}
