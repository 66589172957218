.image {
  position: relative;
  width: 100%;
  height: 100%;
}

.image--slide-reveal {
  overflow: hidden;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 200%;
    content: '';
    transition-delay: 200ms;
    transition-timing-function: cubic-bezier(0.3, 0.8, 0.8, 0.3);
    transition-duration: 1s;
    transform: translateX(-50%);
  }

  img {
    transition-timing-function: linear;
    transform: scale(1.15);
  }
}

.image--slide-reveal.loaded {
  img {
    transition-delay: 700ms;
    transform: scale(1);
  }
  &::after {
    transform: translateX(50%);
  }
}

//-----------------------------------------
// Color Variations
//-----------------------------------------
.image--slide-reveal.--peach::after {
  background: linear-gradient(90deg, $color-peach-accent, $color-peach-accent 50%, $color-blue 50%);
}

.image--slide-reveal.--red::after {
  background: linear-gradient(90deg, $color-red-accent, $color-red-accent 50%, $color-blue 50%);
}
