.menu-lines {
  display: block;
}

.menu-lines__line {
  display: block;
  width: 2.5rem;
  height: 1px;
  background-color: $color-black;
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform-origin: left center;

  &:nth-child(1) {
    transform: translateY(-0.7rem);
  }

  &:nth-child(3) {
    transform: translateY(0.7rem);
  }
}

//-----------------------------------------
// Transitions
//-----------------------------------------
[data-nav-state='open'] {
  .menu-lines__line:nth-child(1) {
    transform: translateX(0.2rem) translateY(-0.7rem) rotate(40deg);
  }

  .menu-lines__line:nth-child(2) {
    transform: scaleX(0);
  }

  .menu-lines__line:nth-child(3) {
    transform: translateX(0.2rem) translateY(0.7rem) rotate(-40deg);
  }
}
