.marquee {
  padding: 4rem;
  overflow: hidden;
  background-color: white;
  background-image:
          linear-gradient(to right, rgba($color-dark-red, 0.2) 1px, transparent 1px),
          linear-gradient(to bottom, rgba($color-dark-red, 0.2) 1px, transparent 1px);
  background-size: 3.5rem 3.5rem;
}

.marquee__inner {
  display: flex;
  flex-wrap: nowrap;
  transform: translateX(-5rem);
}

.marquee__text {
  font-size: 1.6rem;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  animation: marquee 8s linear infinite;


  span {
    display: inline-block;
    margin: 0 1em;
  }

  @keyframes marquee {
    from {
      transform: translateX(0)
    }
    to {
      transform: translateX(-100%);
    }
  }
}
