//-----------------------------------------
// Breakpoints
//-----------------------------------------
@mixin breakpoint($criteria) {
  @media screen and ($criteria) { @content; }
}

//-----------------------------------------
// Helpers
//-----------------------------------------
@mixin strip-list() {
  padding-left: 0;
  list-style: none;
}

@mixin strip-button() {
  cursor: pointer;
  background: none;
  border: 0;
  appearance: none;
}


@mixin visually-hidden() {
  // sass-lint:disable no-important
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  border: 0;
}

@mixin visually-shown($position: inherit) {
  // sass-lint:disable no-important
  position: $position !important;
  width: auto;
  height: auto;
  margin: 0;
  overflow: auto;
  clip: auto;
}

@mixin line-height-crop($line-height) {
  &::before {
    display: block;
    width: 0;
    height: 0;
    margin-top: calc((1 - #{$line-height}) * 0.5em);
    content: '';
  }
}

