//-----------------------------------------
// Images
//-----------------------------------------
.u-fit-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

//-----------------------------------------
// Visibility
//-----------------------------------------
.u-visually-hidden {
  @include visually-hidden;
}
