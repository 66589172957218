/*
/* General / Mobile
/**********************************************************************/

.site-header {
  --easing-function: cubic-bezier(.64,.04,.35,1);
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 $gutter--small;
}

//-----------------------------------------
// Logo
//-----------------------------------------
.site-header__logo-container {
  position: relative;
  left: -3rem;
  width: 15rem;
  margin: 0 auto;
  overflow: hidden;
}

.site-header__logo-inner {
  background-color: $color-black;
}

//-----------------------------------------
// Items
//-----------------------------------------
.site-header__item {
  @include strip-button;
  display: grid;
  grid-template: 1fr / 1fr;
  align-items: center;
  justify-items: center;
  width: 5rem;
  height: 5rem;
  padding: 0;
  margin-top: 5rem;
  border-radius: 100%;
  opacity: 0;
  transform: translateY(-25%);
  animation: site-header-item-in 500ms linear both;
  animation-delay: 1.5s;

  @keyframes site-header-item-in {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &:hover {
    &::before {
      transform: scale(1.08);
    }
  }

  > * {
    position: relative;
    grid-area: 1 / 1;
  }

  &::before {
    grid-area: 1 / 1;
    width: 100%;
    height: 100%;
    content: '';
    background-color: white;
    border-radius: 100%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition-duration: 150ms;
  }
}

//-----------------------------------------
// Menu Toggle
//-----------------------------------------
.site-header__menu-toggle {
  position: absolute;
  top: 0;
  right: $gutter--small;
  z-index: 3;
}

/*
/* Large Up
/**********************************************************************/
@include breakpoint($large--up) {
  .site-header {
    position: fixed;
    padding: 0 $gutter;
  }

  .site-header__item {
    width: 6rem;
    height: 6rem;
  }

  .site-header__menu-toggle {
    position: fixed;
    top: 0;
    right: $gutter;
    z-index: 3;
  }

  .site-header__logo-container {
    position: relative;
    left: -3rem;
    width: 20rem;
    margin: 0 auto;
    overflow: hidden;

    &.enable-animation {
      transform: translateY(-100%);
      animation: logo-container 0.6s var(--easing-function) both;
      animation-delay: 700ms;

      img {
        transform: translateY(100%);
        animation: logo 0.6s var(--easing-function) both;
        animation-delay: 700ms;
      }
    }
  }

  @keyframes logo-container {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes logo {
    from {
      opacity: 0;
      transform: translateY(150%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  //-----------------------------------------
  // Scrolling Hide/Show
  //-----------------------------------------
  .site-header__logo-inner {
    background-color: $color-black;
    transition: transform 500ms var(--easing-function);
  }
  .site-header__logo-container.hide {
    .site-header__logo-inner {
      transform: translateY(-100%);
    }
  }
}
