.eyebrow {
  position: relative;
  padding-left: 5rem;
  font-size: 2.2rem;
  color: $color-peach-accent;
  text-transform: uppercase;
  letter-spacing: 3px;

  &::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3rem;
    height: 0.4rem;
    content: '';
    background-color: currentColor;
    transform: translateY(-50%);
  }
}
