//-----------------------------------------
// Font Base
//-----------------------------------------
body {
  font-family: $font-family-body;
  font-size: $font-size-body;
  color: $color-black;
  text-rendering: optimizeLegibility;
}

body,
input,
button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  line-height: $line-height;
}

//-----------------------------------------
// Headings
//-----------------------------------------
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: $font-family-heading;
  font-weight: $font-weight-heading;
}

//-----------------------------------------
// Output heading sizes based off heading
// size map in typograhpy settings
//-----------------------------------------
@each $heading, $value in $heading-sizes {
  #{$heading}, .#{$heading} {
    font-size: #{$value};
  }
}

//-----------------------------------------
// Paragraphs
//-----------------------------------------
p {
  line-height: $line-height;
}

//-----------------------------------------
// Links
//-----------------------------------------
a {
  color: currentColor;
  text-decoration: none;
}
