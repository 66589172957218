/*
/* General / Mobile
/**********************************************************************/
.team-slider {
  background-color: $color-black;
  transition: opacity 500ms linear;

  &:hover &__item {
    opacity: 0.5;
  }
}

.team-slider .team-slider__item {
  transition: opacity 200ms linear;

  &:hover {
    opacity: 1;
  }
}


.team-slider__control {
  @include strip-button;
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  background-color: white;
  border-radius: 100%;
  transform: translateY(-50%);

  &.swiper-button-disabled {
    cursor: auto;
    opacity: 0.5;
  }
}

.team-slider__next {
  right: 2rem;
}

.team-slider__previous {
  left: 2rem;

  svg {
    transform: rotate(180deg);
  }
}

/*
/* Medium Up
/**********************************************************************/
@include breakpoint($medium--up) {
  .team-slider {
    transition: opacity 0.5s linear;

    &:hover &__item {
      opacity: 0.5;
    }
  }

  .team-slider .team-slider__item {
    transition: opacity 0.2s linear;

    &:hover {
      opacity: 1;
    }
  }

  .team-slider__controls {
    display: none;
  }
}
