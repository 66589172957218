/*
/* General / Mobile
/**********************************************************************/

.site-footer {
  padding: $gutter--small 0;
  color: $color-peach;
  background-color: $color-black;
}

.site-footer__inner {
  display: grid;
  grid-template: auto auto 1fr / 1fr;
  grid-gap: 2rem;
  justify-items: center;
}

.site-footer__nav {
  ul {
    display: flex;

    > * + * {
      margin-left: 1.5rem;
    }
  }
}

.site-footer__nav-item {
  font-size: clamp(1.3rem, 2vw, 1.5rem);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  letter-spacing: 1px;

  a {
    transition-timing-function: linear;
  }

  &:hover a {
    color: white;

    path {
      fill: white;
    }
  }

  a {
    transition-duration: 100ms;
  }
}

.site-footer__colophon {
  font-size: 1.7rem;
  text-align: center;

  span {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif;
  }
}

.site-footer__social ul {
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  .site-footer__nav-item {
    margin: 0;
  }
}

.site-footer__instagram a {
  display: flex;
  align-items: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 1rem;
    transform: translateY(-0.2rem);
  }

  path {
    fill: $color-peach;
    transition-timing-function: linear;
    transition-duration: 100ms;
  }
}


/*
/* Small Up
/**********************************************************************/
@include breakpoint($small--up) {
  .site-footer__social ul {
    flex-direction: row;
    gap: 0;

    .site-footer__nav-item:not(:first-child) {
      margin-left: 1.5rem;
    }
  }
}

/*
/* Large Up
/**********************************************************************/
@include breakpoint($large--up) {
  .site-footer {
    padding: $gutter 0;
  }

  .site-footer__inner {
    grid-template: 1fr / 1fr 15rem 1fr;
    grid-gap: 0;
    justify-items: start;
    text-align: center;
  }

  .site-footer__social {
    justify-self: end;
  }
}
