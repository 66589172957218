/*
/* General / Mobile
/**********************************************************************/

.overlay-nav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: grid;
  grid-template: 1fr 10vh / 1fr;
  background-color: rgba(48, 42, 45, 0.9);
  transition-timing-function: linear;
}

.overlay-nav__grid-accent {
  position: relative;
  z-index: 1;
  grid-area: 2 / 1;
  background-image: linear-gradient(to bottom, rgba(white, 0.2) 1px, transparent 1px),
                    linear-gradient(to right, rgba(white, 0.2) 1px, transparent 1px);
  background-size: 3rem 3rem;
}

.overlay-nav__inner {
  position: relative;
  grid-column: 1 / 1;
  padding-top: 20rem;
  min-height: 90vh;
  color: white;
  background-color: $color-black;

  &::before {
    position: absolute;
    bottom: -12rem;
    left: 0;
    width: 100%;
    height: 9rem;
    content: '';
    background-color: $color-blue-accent;
  }
}

.overlay-nav__nav {
  counter-reset: mobile-nav;

  ul > * + * {
    margin-top: 1rem;
  }
}

.overlay-nav__nav-item {
  position: relative;
  padding-left: 11rem;
  font-size: 3.4rem;
  font-weight: $font-weight-bold;
  line-height: 1;
  text-transform: uppercase;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 2rem;
    content: "0" counter(mobile-nav);
    counter-increment: mobile-nav;
    transition-timing-function: linear;
    transition-duration: 200ms;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 2px white;
  }

  &:hover {
    &::before {
      -webkit-text-fill-color: white;
    }

    span {
      opacity: 0.5;
      transition-timing-function: linear;
      transition-duration: 200ms;
    }
  }

  &:nth-of-type(1) {
    color: $color-dark-red;
  }

  &:nth-of-type(2) {
    color: $color-peach;
  }

  &:nth-of-type(3) {
    color: $color-off-white;
  }

  &:nth-of-type(4) {
    color: $color-green-accent;
  }

  a {
    display: block;
  }
}

//-----------------------------------------
// Transitions
//-----------------------------------------
[data-nav-state='closed'] {
  .overlay-nav {
    visibility: hidden;
    opacity: 0;
    transition-delay: 0s, 1s;
    transition-property: opacity, visibility;
  }

  .overlay-nav__nav-item {
    opacity: 0;
    transition-delay: 500ms;
    transform: translateY(-25%);
  }
}

[data-nav-state='open'] {
  .overlay-nav {
    visibility: visible;
    opacity: 1;
  }

  .overlay-nav__nav-item {
    opacity: 1;
    transition-delay: calc(var(--i) * 0.1s);
    transition-timing-function: ease;
    transition-duration: 600ms;
    transform: none;
  }
}

/*
/* Large Up
/**********************************************************************/
@include breakpoint($large--up) {
  .overlay-nav {
    grid-template: 1fr / 3vw 70rem;
  }

  .overlay-nav__grid-accent {
    grid-area: 1 / 1;
    background-color: $color-black;
    background-image: linear-gradient(to bottom, rgba(white, 0.2) 1px, transparent 1px);
    background-size: 6rem 6rem;
  }

  .overlay-nav__inner {
    grid-area: 1 / 2;
    min-height: auto;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 12rem;
      height: 100%;
      content: '';
      background-color: $color-blue-accent;
    }
  }

  .overlay-nav__nav {
    ul > * + * {
      margin-top: 0;
    }
  }

  .overlay-nav__nav-item {
    padding-left: 16rem;
    font-size: 5.8rem;
  }
}
