%dashed-focus {
  outline: 2px dashed $color-blue-accent;
  transition-duration: 0ms;
}

a:focus,
button:focus{
  @extend %dashed-focus;
}

.skip-link:focus {
  @include visually-shown(absolute);
  z-index: 1000;
  color: $color-dark-red;
  background-color: #fff;
}
