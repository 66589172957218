/*
/* General / Mobile
/**********************************************************************/

.column-callout {
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(100vh + 10rem);
  background-color: $color-black;
}

.column-callout:hover {
  .column-cta {
    opacity: 0.5;
    transition-timing-function: linear;
    transition-duration: 200ms;

    &:hover {
      opacity: 1;
    }
  }
}

/*
/* Large Up
/**********************************************************************/
@include breakpoint($large--up) {
  .column-callout {
    grid-template-columns: 1fr 1fr;
  }
}
