/*
/* General / Mobile
/**********************************************************************/

//-----------------------------------------
// Layout
//-----------------------------------------
.column-hero {
  display: grid;
  grid-template-columns: 1fr;
  min-height: 100vh;
  background-color: $color-blue;
}

.column-hero__column {
  display: grid;
}

.column-hero__column {
  min-height: 100vh;
}

//-----------------------------------------
// Image Reveal Adjustments
//-----------------------------------------
.column-hero__column-right {
  .image--slide-reveal {
    &::after {
      transition-delay: 600ms;
    }
    img {
      transition-delay: 1s;
    }
  }
}

//-----------------------------------------
// Content
//-----------------------------------------
.column-hero__content {
  padding: 4rem $gutter--small;
  color: white;
  background-color: $color-black;
}

.column-hero__eyebrow {
  margin-bottom: 1rem;
  font-size: clamp(1.8rem, 3vmin, 2.2rem);
}

.column-hero__heading {
  margin-bottom: 1rem;
  font-size: clamp(2.6rem, 4vmin, 4rem);
  text-transform: uppercase;
  letter-spacing: 2.5px;
}


/*
/* Large Up
/**********************************************************************/
@include breakpoint($large--up) {
  .column-hero {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    background-color: $color-blue;
  }

  .column-hero__column-left {
    grid-template: 50% 50% / 1fr;

    picture, img {
      position: absolute; // prevent jump
      width: 100%;
      height: 100%;
    }
  }

  .column-hero__content {
    padding: $gutter;
  }
}
